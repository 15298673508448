import React, { useState } from 'react';
import validator from 'validator';
import { useMutation } from '@apollo/client';
import { mutations } from '../../../../../graphql';
import { styled, useTheme } from '@mui/styles';
import {
  Box,
  Grid,
  Typography,
  InputBase
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ThumbsUpIcon from '@mui/icons-material/ThumbUp';
import SuitPattern from '../../../../../Components/SuitPattern';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton';

const BannerBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.styleguide.maroon,
  width: '100%',
  margin: '50px 0 50px 0',
  padding: 50,
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    padding: 30
  }
}));

const PatternBox = styled(Box)(({theme}) => ({
  height: '100%',
  width: 500,
  position: 'absolute',
  overflowY: 'hidden',
  top: 0,
  right: -50,
  [theme.breakpoints.down('md')]: {
    right: -300
  }
}));

const EmailInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: '5px 0 5px 5px',
  margin: '0 20px 0 10px',
  fontSize: 16,
  width: 300,
  maxHeight: 44,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0
  }
}));

export default function FreeArtcilesBanner ({ subscription, user }) {
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  const [signUpForSamples] = useMutation(mutations.SignUpForSamples);

  const { t } = useTranslation();
  const theme = useTheme();

  const handleInputChange = (e) => {
    const val = e.target.value;
    setInputText(val);
    setError(!validator.isEmail(val));
  };

  const handleSignupClick = async () => {
    setIsLoading(true);
    const email = user ? user.email : inputText;
    await signUpForSamples({
      variables: {
        email,
        subscriptionId: subscription.id
      }
    });
    setIsLoading(false);
    setComplete(true);
  };

  const disabled = !user && (inputText === '' || error !== false);

  const Icon = complete ? ThumbsUpIcon : MailOutlineIcon;
  const message = complete ?
    t('look-out-for-your-article') :
    t('sample-articles-inbox');

  return (
    <BannerBox>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{ zIndex: 5 }}
      >
        <Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
          <Icon sx={{ marginRight: 1, color: theme.palette.styleguide.yellow }} />
          <Typography variant='h2' sx={{ color: '#fff' }}>
            {message}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{
          display: 'flex',
          justifyContent: {
            xs: 'space-between',
            sm: 'flex-end'
          },
          marginTop: {
            xs: '2.5rem',
            sm: 0
          }
        }}>
          {
            !user && (
              <EmailInput
                value={inputText}
                placeholder={t('your-email-address')}
                onChange={handleInputChange}
                inputProps={{ sx: { p: 1 } }}
              />
            )
          }
          <Box sx={{ whiteSpace: 'nowrap' }}>
            {
              !complete && (
                <StoreButton
                  label={t('sign-up')}
                  onClick={handleSignupClick}
                  disabled={disabled}
                  loading={isLoading}
                />
              )
            }
          </Box>
        </Grid>
      </Grid>
      <PatternBox>
        <SuitPattern
          opacity={0.07}
        />
      </PatternBox>
    </BannerBox>
  );
}