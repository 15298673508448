import React from 'react';
import {
  Paper,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import StoreButton from '../../../../Components/Forms/Store/StoreButton';

export default function NoActiveSubscription ({ onStoreClick }) {
  const { t } = useTranslation();

  return (
    <Paper>
        <Typography>
          {t('no-active-subscriptions')}
        </Typography>
        <div>
          <StoreButton
            onClick={onStoreClick}
            label='go-to-store'
          />
        </div>
      </Paper>
  );
}