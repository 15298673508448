import React from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import FileUploader from '../../../../Components/FileUploader.js';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  container: {
    position: 'relative'
  },
  progress: {
    top: 2,
    height: 2,
    width: 158
  },
  buttonProgress: {
    // color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

class PBNUploader extends React.Component {

  state = {
    progress: 0
  };

  fileSpecs = {
    supportedFileTypes: [
      {
        extension: 'pbn',
        contentType: 'text/*'
      }
    ],
    maxSizeKb: 1024
  };

  handleUploadProgress = (progressPercent) => {
    console.log(progressPercent);
  };

  handleUploadComplete = () => {
    const { onUploadComplete } = this.props;
    onUploadComplete();
  };

  // {isLoading === true ? <LinearProgress className={classes.progress} /> : null}

  render () {
    const { classes, parentId, parentType, isLoading, onUploadError, onUploadFilenameGenerated } = this.props;
    return (
      <React.Fragment>
        <div className={classes.container}>
          <FileUploader
            fileSpecs={this.fileSpecs}
            uploadFolder='pbns'
            onUploadStart={() => {
              console.log('onUploadStart');
            }}
            onUploadFilenameGenerated={onUploadFilenameGenerated}
            onUploadProgress={this.handleUploadProgress}
            onUploadComplete={this.handleUploadComplete}
            buttonText='Upload PBN'
            buttonIcon={<CloudUploadIcon className={classes.rightIcon} />}
            filePrefix={`${parentType}_${parentId}`}
            buttonDisabled={isLoading}
            onUploadError={onUploadError}
          />
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </React.Fragment>
    );
  }
}

PBNUploader.propTypes = {
  onUploadComplete: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func,
  onUploadFilenameGenerated: PropTypes.func,
  onUploadError: PropTypes.func.isRequired,
  parentId: PropTypes.number.isRequired,
  parentType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withStyles(styles)(PBNUploader);
