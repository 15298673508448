import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import StoreButton from '../../Forms/Store/StoreButton.js';
import StoreTable from '../StoreTable.js';
import { getPriceAfterDiscount } from '../../../helpers/cart.js';
import { formatPrice } from '../../../helpers/currency.js';
import { buildInternalProductUrl } from '../../../helpers/product.js';

const useStyles = makeStyles(() => ({
  itemTable: {
    '& tr': {
      '& td': {
        border: 'none'
      }
    }
  }
}));

const PurchaseItemTable = ({ purchase, onSendEmailClick, resendId, resendLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Table className={classes.itemTable}>
      {
        purchase.purchaseItems.map((pi, index) => {
          const {
            id,
            price,
            discount,
            product,
            subscriptionPlan,
            giftedToUserId
          } = pi;
          const discountedPrice = getPriceAfterDiscount(price, discount);
          const productLink = buildInternalProductUrl(product ? product : subscriptionPlan.subscription);

          return (
            <TableRow key={index}>
              <TableCell>
                <Link to={productLink}>
                  {product ? product.title : subscriptionPlan.subscription.title}
                </Link>
                {
                  giftedToUserId &&
                    <p>[{t('sent-as-gift')}]</p>
                }
                {
                  subscriptionPlan &&
                  <p>[{t(subscriptionPlan.type)}]</p>
                }
              </TableCell>
              <TableCell sx={{ width: '10%' }}>
                {formatPrice(discountedPrice, purchase.currency)}<br />
                {
                  discount && discount > 0
                    ? <Typography variant='caption'>({t('inc-percent-discount', { percent: discount })})</Typography>
                    : null
                }
              </TableCell>
              <TableCell sx={{ width: '25%' }}>
                {
                  giftedToUserId && onSendEmailClick
                    ? (
                      <StoreButton
                        color='secondary'
                        loading={resendLoading && resendId === id}
                        variant='outlined'
                        onClick={onSendEmailClick(id)}
                        label='resend-email'
                      />
                      )
                    : null
                }
              </TableCell>
            </TableRow>
          )
        })
      }
    </Table>
  );
}

export default function PurchaseTable ({ userPurchases, onSendEmailClick, resendId, resendLoading }) { 
  const { t } = useTranslation();

  // build rows
  const rows = userPurchases.purchases && userPurchases.purchases.map(purchase => {
    if (purchase.status === 'Succeeded') {
      const purchaseItemTable = <PurchaseItemTable
        purchase={purchase}
        onSendEmailClick={onSendEmailClick}
        resendId={resendId}
        resendLoading={resendLoading}
      />;
      return [
        purchase.purchaseDate,
        purchaseItemTable,
        purchase.totalPriceFormatted
      ];
    }
    return null;
  });

  const tableData = {
    columns: [
      t('date'),
      t('product-items'),
      t('total-price'),
      ''
    ],
    rows
  };

  return (
    <StoreTable
      tableData={tableData}
    />
  );
};