import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Paper
} from '@mui/material';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton.js';
import AddToCartButton from '../../../../../Components/Store/AddToCartButton.js';
import PlanButton from './PlanButton.js';
import SendAsGiftButton from '../../../../../Components/Store/SendAsGiftButton.js';
import { CartContext } from '../../../../../Context';
import { getProductPriceByCurrency } from '../../../../../helpers/cart.js';
import { formatPrice } from '../../../../../helpers/currency.js';
import { getPlanByType } from '../../../../../helpers/subscription.js';

const PlanChooserCard = styled(Card)(({ theme }) => ({
  width: 360,
  textAlign: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.common.oatmeal,
  border: '1px solid',
  borderColor: theme.palette.styleguide.maroon,
  [theme.breakpoints.down(400)]: {
    width: '100%'
  }
}));

const PlanButtonsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 18,
  gap: '1.6rem',
  [theme.breakpoints.down(400)]: {
    marginTop: 0,
    gap: '0.8rem'
  }
}));

const SaveTenPercent = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.styleguide.pink,
  color: theme.palette.white.main,
  padding: 4,
  width: 136,
  maxWidth: '100%',
  marginTop: '1.6rem',
  [theme.breakpoints.down(400)]: {
    marginTop: '0.8rem'
  }
}));

const BottomButtonsBox = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.down(400)]: {
    gap: theme.spacing(1),
    padding: '0.8rem 0 0 0 !important',
    paddingTop: theme.spacing(1)
  }
}));

export default function PlanChooser({ subscription, isSubscribed }) {
  const [chosenPlanType, setChosenPlanType] = useState('yearly')

  const { currency } = useContext(CartContext); 

  const navigate = useNavigate();
  const { t } = useTranslation();

  const chosenPlan = getPlanByType(subscription, chosenPlanType);
  const yearlyPlan = getPlanByType(subscription, 'yearly');
  const monthlyPlan = getPlanByType(subscription, 'monthly');

  const handlePlanChange = (plan) => {
    setChosenPlanType(plan);
  };

  const handleLibraryClick = () => {
    navigate('/library');
  };

  const monthlyPrice = formatPrice(getProductPriceByCurrency(currency, monthlyPlan.price), currency);
  const yearlyPrice = formatPrice(getProductPriceByCurrency(currency, yearlyPlan.price), currency);

  return (
    <PlanChooserCard>
      {
        isSubscribed ?
        (
          <>
            <CardContent>
              <Box>{t('you-are-already-subscribed')}</Box>
              <StoreButton
                onClick={handleLibraryClick}
                label='go-to-library'
              />
            </CardContent>
          </>
        )
        :
        (
          <>
            <PlanButtonsBox>
              <Box sx={{ maxWidth: '50%' }}>
                <PlanButton
                  type='monthly'
                  price={monthlyPrice}
                  active={chosenPlanType === 'monthly'}
                  onClick={handlePlanChange}
                />
              </Box>
              <Box sx={{ maxWidth: '50%' }}>
                <PlanButton
                  type='yearly'
                  price={yearlyPrice}
                  active={chosenPlanType === 'yearly'}
                  onClick={handlePlanChange}
                />
                <SaveTenPercent>
                  {t('save-10-pct')}
                </SaveTenPercent>
              </Box>
            </PlanButtonsBox>
            <BottomButtonsBox>
              <AddToCartButton item={chosenPlan} fullWidth />
              <SendAsGiftButton item={chosenPlan} fullWidth />
            </BottomButtonsBox>
          </>
        )
      }
    </PlanChooserCard>
  )
}
