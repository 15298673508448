import React from 'react';
import StoreButton from '../../../../Components/Forms/Store/StoreButton';
import StoreTable from '../../../../Components/Store/StoreTable';
import { useTranslation } from 'react-i18next';

const Actions = (props) => {
  const {
    userSubscriptionPlanId,
    userSubscriptionPlanType,
    onCancelSubscriptionClick,
    onChangeSubscriptionClick
  } = props;
  const handleCancelClick = () => {
    onCancelSubscriptionClick(userSubscriptionPlanId);
  };

  const handleChangeClick = () => {
    onChangeSubscriptionClick(userSubscriptionPlanId);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 10 }}>
        <StoreButton onClick={handleCancelClick} label='cancel'/>
      </div>
      {
        userSubscriptionPlanType === 'Monthly' && (
          <div>
            <StoreButton
              onClick={handleChangeClick}
              label={'change-to-yearly'}
            />
          </div>
        )
      }
    </div>
  );
}

export default function SubscriptionTable ({ userSubscriptionPlans, onCancelSubscriptionClick, onChangeSubscriptionClick }) {
  const { t } = useTranslation();

  const rows = userSubscriptionPlans.map(usp => {
    return [
      usp.subscriptionPlan.subscription.title,
      usp.subscriptionPlan.type,
      usp.startDateTimeUniversal,
      <Actions
        userSubscriptionPlanId={usp.id}
        userSubscriptionPlanType={usp.subscriptionPlan.type}
        onCancelSubscriptionClick={onCancelSubscriptionClick}
        onChangeSubscriptionClick={onChangeSubscriptionClick}
      />
    ]
  });

  const tableData = {
    columns: [
      t('product'),
      t('plan'),
      t('start-date'),
      t('actions')
    ],
    rows
  };

  return (
    <StoreTable tableData={tableData} />
  );
}