import { useContext } from 'react';
import PropTypes from 'prop-types';
import { LocaleContext } from '../../Context';

import moment from 'moment';
// NOTE - any new languages need to be manually imported like so:
import 'moment/locale/nl';

const LocalisedDisplayDate = ({ date }) => {
  const { locale } = useContext(LocaleContext);

  let dateString;

  if (locale && locale.language) {
    dateString = moment(date)
      .locale(locale.language)
      .format('MMMM Do YYYY');
  } else {
    dateString = moment(date).format('MMMM Do YYYY');
  }

  dateString = dateString[0].toUpperCase() + dateString.slice(1);

  return dateString;
};

LocalisedDisplayDate.propTypes = {
  date: PropTypes.string.isRequired
};

export default LocalisedDisplayDate;
