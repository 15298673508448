import gql from 'graphql-tag';
import subscriptionPlanFragments from './subscriptionPlan.js';

const subscriptionFragments = {
  fullSubscription: gql`
    fragment FullSubscription on Subscription {
      id
      title
      description
      images
      tagsCache
      onSale
      author {
        id
        fullName
        avatarPath
      }
      tags {
        id
        name
        tagGroup {
          id
          name
        }
      }
      subscriptionPlans {
        ...FullSubscriptionPlan
      }
    }
    ${subscriptionPlanFragments.fullSubscriptionPlan}
  `
};

export default subscriptionFragments;
