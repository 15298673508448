import React, { useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { articleQueries } from '../../../../graphql/queries';
import HandDetails from './HandDetails.js';
import {
  getNextHand,
  formatPathToArticleHandPlay
} from '../../../../helpers/hand.js';
import { 
  HandContext,
  UserContext
} from '../../../../Context';
import { useHandAndParentQuery } from '../../../../Hooks';

const ArticleHandDetails = () => {
  let {
    articleId,
    handId
  } = useParams();

  handId = parseInt(handId);

  const { user } = useContext(UserContext);

  const { init: initHandContext } = useContext(HandContext);

  const queryResponse = useHandAndParentQuery({
    handId,
    parentId: articleId,
    parentQuery: articleQueries.LatestArticleOrByPublicId
  });

  const getNextHandUrl = useCallback((article) => {
    const nextHand = getNextHand(handId, article.hands);
    if (nextHand) {
      return formatPathToArticleHandPlay(article, nextHand);
    }
    return undefined;
  }, [handId]);

  useEffect(() => {
    if (queryResponse) {
      const { latestArticleOrByPublicId: article } = queryResponse.parentData;
      initHandContext({
        hand: queryResponse.handData.handById,
        parent: article,
        grandParent: article.subscription,
        nextHandUrl: getNextHandUrl(article),
        currentUser: user
      });
    }
  }, [queryResponse, initHandContext, getNextHandUrl, user]);

  if (!queryResponse) {
    return null;
  }

  return (
    <HandDetails />
  );
};

export default ArticleHandDetails;
